
html{
    height: 100%;
}

body {
    min-height: 100%;
}

#root {
  height:100vh;
}

.login .btn {
  padding: 9px 26px;
  background-color: #9A0059;
  color: #FFF;
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-decoration: none;
  border: solid 2px #9A0059;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px; }
  .login .btn:hover {
    color: #FFF;
    background-color: rgb(107, 5, 64);
    border-color: rgb(107, 5, 64); }

.fdb-block.login {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  padding: 100px 0;
  color: #444444;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;
   }
  .fdb-block.login .fdb-box {
    background: #FFFFFF;
    color: #231f20;
    padding: 60px 40px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    overflow: hidden; }
  .fdb-block.login .fdb-touch {
    border-top: solid 5px #9A0059; }
  .fdb-block.login h1, .fdb-block.login .text-h1 {
    font-size: 2.75rem;
    margin-bottom: .5em;
    margin-top: .3em;
    font-weight: 400; }

  .fdb-block.login img {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px; }
    .fdb-block.login img + h1, .fdb-block.login img + h2 {
      margin-top: 15px; }
