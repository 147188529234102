#usersSelected{
    width:100%;
    height:600px;
    overflow: auto;
  }

.emp-purple{
    background-color:#8E064F;
}

.white{
    color:white;
}

.selected{
    height: 400px;
    overflow: auto;
}


#cloturer{
    float: right;
}

.margin{
    margin-top:10px;
}

.right{
    float:right;
}
